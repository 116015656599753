export class TreeNodeFactory {  
  constructor() {
    this.currentindex = 0;
  }

  createEmptyParentNode(name) {
    let node = this.createEmptyNode(name);
    node.parent = true;
    return node;
  }
  createNewNodeFromDTO(dto) {
    let node = this.createEmptyNode(dto.label);
    node.payload = {id:dto.id, name:dto.label};
    return node;
  }

  createEmptyNode(name) {
    return {
      id: ++this.currentindex,
      name: name,
      payload: null,
      integrityerror: null,
      integritywarning: null,
      editable: {type: Boolean, default: true},
      children: [],
      parent: false
    };
  }
  
}
  
export class TreeModelConverter {
  
  constructor(treeNodeFactory) {
      this.treeNodeFactory = treeNodeFactory;
  }
  
  convertDtoToTreeModel(treedto) {
    let root = this.treeNodeFactory.createNewNodeFromDTO({name:"root"})
    treedto.capacities.forEach((subnode) => {
      let dto = this.buildDtoToTreeModel(subnode);
      dto.parent = true;
      root.children.push(dto);
    });
    return root;
  }
  
  buildDtoToTreeModel(nodedto)  {
    let newnode = this.treeNodeFactory.createNewNodeFromDTO(nodedto);
    if(nodedto.subCapacities) {
      nodedto.subCapacities.forEach((subnodedto) => {
        newnode.children.push(this.buildDtoToTreeModel(subnodedto));
      })
    }
    return newnode;
  }
  
  convertTreeModelToDto(treemodel, activityId) {
    let tree = this.buildTreeModelToDto(treemodel);
    tree.subCapacities.forEach(parentCapa => {
      parentCapa.activityId = activityId;
    })
    return tree.subCapacities;
  }
  
  buildTreeModelToDto(nodetreemodel)  {      
    let newnodedto = {id: nodetreemodel.payload ? nodetreemodel.payload.id : null, label: nodetreemodel.name, subCapacities:[]};
    if(nodetreemodel.children) {
      nodetreemodel.children.forEach((subnodedto) => {
        newnodedto.subCapacities.push(this.buildTreeModelToDto(subnodedto));
      })
    }
    return newnodedto;
  }
} 

export default TreeNodeFactory;
  