<template>
  <v-dialog 
  v-model="show"
  :width="witdthDialog"
  persistent>

    <v-card>
      <v-card-title class="mb-4">
        <v-row no-gutters>
          <v-icon 
          color="red"
          class="mr-2">
            mdi-alert-outline
          </v-icon>
          <span class="red--text">
          {{ title }}
          </span>
        </v-row>
      </v-card-title>

      <v-card-text
      v-if="!labelHtml">
        {{ label }}
      </v-card-text>
      
      <v-card-text
      v-if="labelHtml">
        <span v-html="label"></span>
      </v-card-text>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="primary"
        class="btn ma-2 px-4"
        @click="close()">
        {{ textButton }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardDialogWarning",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    labelHtml: {
      type: Boolean,
      default: false,
    },
    textButton: {
      type: String,
      default: "",
    },
    witdthDialog: {
      type: String,
      default: "400px",
    },
  },

  data() {
    return {
      show: this.visible,
    };
  },

  methods: {
    // Fermeture de la dialog
    close() {
      this.show = false;
      this.$emit("update:visible", false);
    },
  },

  watch: {
    visible(value) {
      this.show = value;
    },
    show(value) {
      //on ferme le dialog, il faut informer que le composant n'est plus visible
      if (!value) {
        this.$emit("update:visible", false);
      }
    },
  },

  mounted() {},
}
</script>