var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tree
    ? _c(
        "div",
        [
          _vm.editable
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "10",
                            justify: "center",
                            "align-self": "start",
                          },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "pa-md-0 ps-md-8",
                            attrs: {
                              dense: "",
                              placeholder: "Nouvelle capacité parente",
                              rules: [
                                (value) =>
                                  _vm.checkUniqueLabelOnNodes(
                                    _vm.tree.children,
                                    value
                                  ),
                              ],
                            },
                            on: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.addParentNode.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.newParent,
                              callback: function ($$v) {
                                _vm.newParent = $$v
                              },
                              expression: "newParent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2", "align-self": "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 px-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: !_vm.canAddNewParent,
                              },
                              on: { click: _vm.addParentNode },
                            },
                            [_vm._v("Ajouter")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("v-divider"),
          _c("v-treeview", {
            attrs: {
              "expand-icon": "mdi-none",
              dense: "",
              "active.sync": "",
              activatable: _vm.editable,
              items: _vm.tree.children,
              open: _vm.openNodes,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function ({}) {
                    return [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(" " + _vm._s("mdi-minus") + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "label",
                  fn: function ({ item, active }) {
                    return [
                      !active
                        ? _c(
                            "div",
                            {
                              class: item.parent ? `subtitle-1` : ``,
                              attrs: { center: "" },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        : _c("div", [
                            item.integrityerror
                              ? _c("div", [_vm._v(_vm._s(item.integrityerror))])
                              : _vm._e(),
                            item.integritywarning
                              ? _c("div", [
                                  _vm._v(_vm._s(item.integritywarning)),
                                ])
                              : _vm._e(),
                            active
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "pa-md-0",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "pa-md-0" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "pa-md-0",
                                                  attrs: {
                                                    dense: "",
                                                    rules: [
                                                      (value) =>
                                                        _vm.checkUniqueLabelOnParent(
                                                          item.id,
                                                          value
                                                        ),
                                                    ],
                                                  },
                                                  model: {
                                                    value: item.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { md: "auto" } },
                                              [
                                                active
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          align: "start",
                                                          color: "primary",
                                                          icon: "",
                                                          title:
                                                            "Supprimer cette capacité",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onDeleteItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-window-close"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("DeleteTableItemDialog", {
                                                  attrs: {
                                                    label:
                                                      _vm.getDeleteTableItemDialogMessage(
                                                        item
                                                      ),
                                                    visible:
                                                      _vm.showDeleteDialog,
                                                  },
                                                  on: {
                                                    "update:visible": function (
                                                      $event
                                                    ) {
                                                      _vm.showDeleteDialog =
                                                        $event
                                                    },
                                                    confirmed: function (
                                                      $event
                                                    ) {
                                                      return _vm.deleteItem(
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-container",
                                      { staticClass: "pa-md-0" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "pa-md-0 ps-md-8",
                                                  attrs: {
                                                    dense: "",
                                                    placeholder:
                                                      "Ajouter une sous-capacité",
                                                    rules: [
                                                      (value) =>
                                                        _vm.checkUniqueLabelOnNode(
                                                          item,
                                                          value
                                                        ),
                                                    ],
                                                  },
                                                  on: {
                                                    keypress: function (
                                                      $event
                                                    ) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.onAddItemWithName(
                                                        item,
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.newChild,
                                                    callback: function ($$v) {
                                                      _vm.newChild = $$v
                                                    },
                                                    expression: "newChild",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                    ]
                  },
                },
              ],
              null,
              false,
              1390982550
            ),
          }),
          _c("StandardDialogWarning", {
            attrs: {
              visible: _vm.showError,
              title: "Impossible de supprimer cette capacité",
              label: _vm.errorOnDeleteNode,
              labelHtml: true,
              textButton: "Fermer",
              witdthDialog: "600px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showError = $event
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }