var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.witdthDialog, persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "mb-4" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "red" } },
                    [_vm._v(" mdi-alert-outline ")]
                  ),
                  _c("span", { staticClass: "red--text" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.labelHtml
            ? _c("v-card-text", [_vm._v(" " + _vm._s(_vm.label) + " ")])
            : _vm._e(),
          _vm.labelHtml
            ? _c("v-card-text", [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
              ])
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn ma-2 px-4",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.textButton) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }