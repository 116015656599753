<template>
  <div v-if="tree">
    <v-container v-if="editable">
      <v-row no-gutters align="center">
        <v-col cols="10" justify="center" align-self="start">
          <v-text-field
            dense
            class="pa-md-0 ps-md-8"
            placeholder="Nouvelle capacité parente"
            @keypress.enter="addParentNode"
            v-model="newParent"
            :rules="[(value) => checkUniqueLabelOnNodes(tree.children, value)]"
          ></v-text-field>
        </v-col>
        <v-col cols="2" align-self="center">
          <v-btn
            outlined
            class="ma-2 px-4 btn"
            color="primary"
            @click="addParentNode"
            :disabled="!canAddNewParent"
            >Ajouter</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-treeview
      expand-icon="mdi-none"
      dense
      active.sync
      :activatable="editable"
      :items="tree.children"
      :open="openNodes"
    >
      <template v-slot:prepend="{}">
        <v-icon color="primary">
          {{ "mdi-minus" }}
        </v-icon>
      </template>
      <template v-slot:label="{ item, active }">
        <div v-if="!active" :class="item.parent ? `subtitle-1` : ``" center>
          {{ item.name }}
        </div>
        <div v-else>
          <div v-if="item.integrityerror">{{ item.integrityerror }}</div>
          <div v-if="item.integritywarning">{{ item.integritywarning }}</div>
          <div class="pa-md-0" v-if="active" @click.stop="">
            <v-container class="pa-md-0">
              <v-row>
                <v-col>
                  <v-text-field
                    class="pa-md-0"
                    dense
                    v-model="item.name"
                    :rules="[
                      (value) => checkUniqueLabelOnParent(item.id, value),
                    ]"
                  ></v-text-field> </v-col
                ><v-col md="auto">
                  <v-btn
                    align="start"
                    v-if="active"
                    color="primary"
                    icon
                    @click="onDeleteItem(item)"
                    title="Supprimer cette capacité"
                  >
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                  <!-- Popup de confirmation de suppression d'un noeud-->
                  <DeleteTableItemDialog
                    :label="getDeleteTableItemDialogMessage(item)"
                    :visible.sync="showDeleteDialog"
                    @confirmed="deleteItem(item)"
                  ></DeleteTableItemDialog>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="pa-md-0">
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    class="pa-md-0 ps-md-8"
                    placeholder="Ajouter une sous-capacité"
                    @keypress.enter="
                      onAddItemWithName(item, $event.target.value)
                    "
                    :rules="[(value) => checkUniqueLabelOnNode(item, value)]"
                    v-model="newChild"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </template>
    </v-treeview>

    <StandardDialogWarning
    :visible.sync="showError"
    title="Impossible de supprimer cette capacité"
    :label="errorOnDeleteNode"
    :labelHtml="true"
    textButton="Fermer"
    witdthDialog="600px"
    />


    <!-- Popup d'erreur de suppression d'un noeud  -->
    <!-- <v-dialog v-model="showError" max-width="1000px" persistent>
      <div>
        <v-alert prominent type="error" colored-border border="left">
          <v-row align="center">
            <v-col class="grow">
              <div v-html="errorOnDeleteNode"></div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                outlined
                text
                @click="closeDialogError()"
                class="my-2 px-4 btn"
                color="red"
                >Fermer</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </v-dialog> -->
  </div>
</template>
<style>
/* alignement vertical de l'icone sur un noeud de l'arbre */
.v-treeview-node__root {
  align-items: baseline;
}
.v-treeview-node__content {
  align-items: baseline;
}
</style>
<script>

import * as logger from "@/tools/logger.js";

class TreeModelBusiness {
  constructor(parent) {
    this.parent = parent;
  }

  loggerNode = function (node) {
    logger.debug(node.id);
  };
  loggerParent = function (parentNode, node) {
    logger.debug(parentNode.id + " -> " + node);
  };

  deleteFromParent = function (parentNode, node, validation = null) {
    let idx = parentNode.children.indexOf(node);
    if (validation) {
      let val = validation();
      if (val.error) {
        node.integrityerror = val.error;
      }
      if (val.warning) {
        node.integritywarning = val.warning;
      }
      if (!val.canOperate) {
        return true;
      }
    }
    parentNode.children.splice(idx, 1);
    return true;
  };
  addOnParent = function (parentNode, node, newone, validation = null) {
    if (validation) {
      let val = validation(newone);
      if (val.error) {
        parentNode.integrityerror = val.error;
      }
      if (val.warning) {
        parentNode.integritywarning = val.warning;
      }
      if (!val.canOperate) {
        return true;
      }
    }
    if (!parentNode.children) parentNode.children = [newone];
    else parentNode.children = [newone, ...parentNode.children];
    return true;
  };

  logger = function (node) {
    logger.debug(node.id);
  };

  resetWarningsAndErrors(model) {
    this.actionOnAllNode(model, (node) => {
      node.integrityerror = false;
      node.integritywarning = false;
    });
  }
  deleteNodeByIdOnTree(model, id) {
    this.resetWarningsAndErrors(model);
    this.actionOnParentNodeById(model, id, (parentNode, node) =>
      this.deleteFromParent(parentNode, node)
    );
  }
  addNodeOnParentByIdOnTree(treeNodeFactory, model, id, name = "") {
    this.resetWarningsAndErrors(model);
    let newEmptyNode = treeNodeFactory.createEmptyNode(name, null);
    if (this.parent.canAddNode) {
      this.actionOnNodeById(model, id, (parentNode, node) =>
        this.addOnParent(parentNode, node, newEmptyNode, (newnode) =>
          this.parent.canAddNode(model, parentNode, newnode)
        )
      );
    } else {
      this.actionOnNodeById(model, id, (parentNode, node) =>
        this.addOnParent(parentNode, node, newEmptyNode)
      );
    }
    return newEmptyNode.id;
  }

  isLabelExistOnNode(node, newname) {
    if (node && node.children) {
      for (let subnode of node.children) {
        if (
          subnode.name &&
          newname &&
          subnode.name.toLowerCase() === newname.toLowerCase()
        )
          return true;
      }
    }
    return false;
  }

  isLabelExistOnNodes(nodes, newname) {
    if (nodes) {
      for (let subnode of nodes) {
        if (
          subnode.name &&
          newname &&
          subnode.name.toLowerCase() === newname.toLowerCase()
        )
          return true;
      }
    }
    return false;
  }

  isLabelExistOnParentNode(model, id, newname) {
    let parent = this.findParentForChildId(model, id);
    if (parent && parent.children) {
      for (let subnode of parent.children) {
        if (
          subnode.id != id &&
          subnode.name &&
          newname &&
          subnode.name.toLowerCase() === newname.toLowerCase()
        )
          return true;
      }
    }
    return false;
  }

  actionOnNodeById(node, id, action) {
    if (node.id === id) {
      return action(node);
    } else {
      if (node.children) {
        for (let subnode of node.children) {
          if (this.actionOnNodeById(subnode, id, action)) return true;
        }
      }
    }
    return false;
  }

  actionOnParentNodeById(node, id, action) {
    if (node.children) {
      for (let subnode of node.children) {
        if (subnode.id === id) {
          if (action(node, subnode)) {
            return true;
          }
        }
        if (this.actionOnParentNodeById(subnode, id, action)) return true;
      }
    }
    return false;
  }

  actionOnAllNode(node, action) {
    action(node);
    if (node.children) {
      node.children.forEach((subnode) => {
        this.actionOnAllNode(subnode, action);
      });
    }
  }

  findParentForChildId(node, id) {
    if (node.children) {
      for (let subnode of node.children) {
        if (subnode.id === id) return node;
        let val = this.findParentForChildId(subnode, id);
        if (val) {
          return val;
        }
      }
    }
    return null;
  }

  loadOpenNodes(allNodes, tree) {
    allNodes.push(tree.id);
    if (tree.children && tree.children.length > 0) {
      for (let subnode of tree.children) {
        this.loadOpenNodes(allNodes, subnode);
      }
    }
    return allNodes;
  }

  addParentNode(treeNodeFactory, tree, newParent, openNodes) {
    let newNode = treeNodeFactory.createEmptyParentNode(newParent);
    tree.children.push(newNode);
    openNodes.push(newNode.id);
  }

  logTree(node, level) {
    let str = "";
    for (let i = 0; i < level; i++) str += "--";
    logger.debug(str + "[" + node.id + ":" + node.name + "]");
    if (node.children) {
      node.children.forEach((subnode) => {
        this.logTree(subnode, level + 1);
      });
    }
  }
}

import DeleteTableItemDialog from "@/components/ui/DeleteTableItemDialog.vue";
import * as stringTools from "@/tools/string_tool.js";

import StandardDialogWarning from "@/components/ui/StandardDialogWarning.vue";


export default {
  name: "TreeViewLabelComponent",
  components: {
    DeleteTableItemDialog,
    StandardDialogWarning,
  },
  props: {
    treeNodeFactory: null,
    tree: null,

    editable: { type: Boolean, default: false },

    canAddNode: { type: Function, default: null },
    canRemoveNode: { type: Function, default: null },
    canRenameNode: { type: Function, default: null },
  },
  data() {
    return {
      treeBusiness: null,
      initialTree: JSON.parse(JSON.stringify(this.tree)),

      // L'ajout d'un nouveau parent à l'arbre
      newParent: null,

      // Le nouvel élément ajouté. Permet de vider la valeur du textfield après son ajout
      newChild: undefined,

      // La liste des noeud ouverts
      openNodes: [],

      /** rendre visible ou non la dialog de warning suppression */
      showDeleteDialog: false,

      // L'apparition ou non de la dialog d'erreur sur suppression d'un noeud
      showError: false,
      // message d'erreur lors de la suppression d'un noeud
      errorOnDeleteNode: null,
    };
  },
  computed: {
    canAddNewParent() {
      if(this.treeBusiness) {
        return !stringTools.isNullOrEmptyOrSpaces(this.newParent) && (!this.treeBusiness.isLabelExistOnNodes(this.tree.children,this.newParent));
      }
      return false;
    }
  },
  methods: {
    /** Fermeture de la popup d'erreur */
    closeDialogError() {
      this.showError = false;
      this.errorOnDeleteNode = null;
    },
    async onDeleteItem(item) {
      this.showDeleteDialog = false;
      let isNewNode = item.payload === null;
      // on peut supprimer l'élément si c'est un élément qui n'est pas encore persisté
      // ou si c'est un élément que l'on a le droit de supprimer (verification par appel au callback canRemoveNode)
      if (isNewNode) {
        this.showDeleteDialog = true;
      } else {
        let canRemoveNode =
          this.canRemoveNode && (await this.canRemoveNode(item));
        if (canRemoveNode.canOperate) {
          this.showDeleteDialog = true;
        } else {
          item.integrityerror = canRemoveNode.errorLight;
          this.errorOnDeleteNode = canRemoveNode.error;
          this.showError = true;
        }
      }
    },
    async deleteItem(item) {
      this.treeBusiness.deleteNodeByIdOnTree(this.tree, item.id);
    },
    onAddItem(id) {
      let newNodeId = this.treeBusiness.addNodeOnParentByIdOnTree(
        this.treeNodeFactory,
        this.tree,
        id
      );
      this.openNodes.push(newNodeId);
    },
    onAddItemWithName(item, name) {
      if(!stringTools.isNullOrEmptyOrSpaces(name)){
        if (!this.treeBusiness.isLabelExistOnNode(item, name)) {
          let newNodeId = this.treeBusiness.addNodeOnParentByIdOnTree(
            this.treeNodeFactory,
            this.tree,
            item.id,
            name
          );
          this.newChild = null;
          this.openNodes.push(newNodeId);
        }
      }
    },
    checkUniqueLabelOnParent(id, newname) {
      if (this.treeBusiness.isLabelExistOnParentNode(this.tree, id, newname))
        return "Le nom de la capacité doit être unique parmi les capacités de même rang.";
        
      return true;
    },
    checkUniqueLabelOnNode(node, newname) {
      if (this.treeBusiness.isLabelExistOnNode(node, newname))
        return "Le nom de la capacité doit être unique parmi les capacités de même rang.";

      return true;
    },
    checkUniqueLabelOnNodes(nodes, newname) {
      if (this.treeBusiness.isLabelExistOnNodes(nodes, newname))
        return "Le nom de la capacité doit être unique parmi les capacités de même rang.";

      return true;
    },
    addParentNode() {
      if (
        this.canAddNewParent
      ) {
        this.treeBusiness.addParentNode(
          this.treeNodeFactory,
          this.tree,
          this.newParent,
          this.openNodes
        );
        this.newParent = null;
      }
    },
    getDeleteTableItemDialogMessage(item) {
      if (item.children.length == 0) {
        return "Voulez-vous supprimer la capacité <b>" + item.name + "</b> ? ";
      } else
        return (
          "Voulez-vous supprimer la capacité <b>" +
          item.name +
          "</b> ainsi que toutes ses sous-capacités ? "
        );
    },
  },

  watch: {
    tree: function () {
      this.openNodes = [];
      this.treeBusiness.loadOpenNodes(this.openNodes, this.tree);
    },
  },
  mounted() {
    this.treeBusiness = new TreeModelBusiness(this);
  },
};
</script>
